<template>
	<custom-page>
		<!---->
		<group-card title="custom.page1.芯体" in-line>
			<!--长-->
			<group-item label="custom.page1.长">{{ calcRes.dLength }}{{userUnit.length}}</group-item>
			<!--扁管数量-->
			<group-item label="custom.page2.扁管数量">{{ calcRes.Ntube }}</group-item>
			<!--高-->
			<group-item label="custom.page1.高">{{ calcRes.dHeight }}{{userUnit.length}}</group-item>
			<!--扁管型号-->
			<group-item label="custom.page2.扁管型号">{{ `${dTube.w}*${dTube.h}*${dTube.n}` }}</group-item>
			<!--排数-->
			<group-item label="custom.page1.排数">{{ calcModel.iBank }}</group-item>
			<!--翅片型号-->
			<group-item label="custom.page2.翅片型号">{{ `${dFin.thick}*${dFin.w}*${dFin.h} FPI${dFin.fpi}` }}</group-item>
			<!--流程-->
			<group-item label="custom.page2.流程">{{ calcRes.NPass }}</group-item>
			<group-item label="newadd.t.内容积">{{ calcRes.dVolin }}L</group-item>					
		</group-card>

		<group-card title="custom.page2.性能" in-line>
			<!--换热量-->
			<group-item label="custom.page2.换热量">{{ calcRes.dQ }}{{userUnit.heatLoad}}</group-item>
			
			<!--冷媒-->
			<group-item label="custom.page0.冷媒">{{ calcModel.sRefName }}</group-item>
			
			<!--环境温度-->
			<group-item label="custom.page0.环境温度">{{ calcModel.dAirInT }}{{userUnit.temperature}}</group-item>
			
			<!--排气温度-->
			<group-item label="custom.page0.排气温度">{{ calcModel.dRefInT }}{{userUnit.temperature}}</group-item>
			
			<!--出风温度-->
			<group-item label="custom.page2.出风温度">{{ calcRes.dTout_a }}{{userUnit.temperature}}</group-item>
			
			<!--排气压力-->
			<group-item label="custom.page0.排气压力">{{ calcModel.dRefInP }}{{userUnit.pressure}}</group-item>
			
			<!--风速-->
			<group-item label="custom.page0.风速">{{ calcRes.dVel_a }}{{userUnit.velocity}}</group-item>
			<!--过冷度-->
			<group-item label="custom.page0.过冷度" v-if="calcModel.iPatt == 0">{{ calcModel.dRefOutSC }}{{userUnit.temperature}}</group-item>
			<group-item label="custom.page0.工质流量" v-if="calcModel.iPatt == 1">{{ calcModel.dRefVF }}{{"L/min"}}</group-item>
			<!--风量-->
			<group-item label="custom.page0.风量">{{ calcRes.dVF_a }}{{userUnit.volumeFlow}}</group-item>
			<!--流量-->
			<group-item label="custom.page2.流量">{{ calcRes.dMF_r }}{{userUnit.massFlow}}</group-item>
			<!--风压-->
			<group-item label="custom.page0.风压">{{ calcRes.dDP_a }}{{userUnit.airPressureDrop}}</group-item>
			<!--冷媒降压-->
			<group-item label="custom.page2.冷媒降压">{{ calcRes.dDP_r }}{{userUnit.refPressureDrop}}</group-item>
			
			<!--冷媒充注量-->
			<!-- <group-item label="custom.page2.冷媒充注量">{{ calcRes.dCharge }}{{userUnit.charge}}</group-item> -->
		</group-card>
		<div class="action-bar">
			<el-button type="" class="btn" @click="handlePrevClick">{{ $t("common.actions.back") }}</el-button>
			<el-button type="primary" class="btn" @click="handleNextClick">{{ $t("common.actions.confirm") }}
			</el-button>
		</div>
	</custom-page>
</template>

<script>
	import CustomPage from "@/views/Custom/components/CustomPage";
	import GroupCard from "@/views/Custom/components/GroupCard";
	import GroupItem from "@/views/Custom/components/GroupItem";
	import mixin from "@/views/Custom/components/mixins/mixins";
	import {
		getGoodsCustomMode
	} from "@/utils";
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "page2",
		components: {
			GroupItem,
			GroupCard,
			CustomPage
		},
		data() {
			return {
				userUnit:{
					
				}
			}
		},
		mixins: [mixin],
		computed: {
			...mapGetters(["customInfo", "calcRes", "calcModel"]),
			dTube() {
				return this.calcModel.dTube || {};
			},
			dFin() {
				return this.calcModel.dFin || {};
			}
		},
		mounted() {
			   this.getUserUnit();
		},
		methods: {
			async getUserUnit() {
			  this.userUnit = await this.$store.dispatch("GetUserUnitOrCache");
			  
			},
			
			
		},
	}
</script>

<style scoped lang='scss'>
	.action-bar {
		text-align: center;

		.btn {
			width: 330px;
		}
	}
</style>
